<template>
  <div>
    <div
      v-editable="blok"
      class="callout"
      :style="{
        '--overlay-alpha': `${blok.background_alpha}`,
        backgroundImage: `url(${image()})`,
      }"
    >
      <div class="callout__content">
        <div class="callout__headline">
          <CustomHeading :blok="blok.headline[0]" />
          <RichTextEditor
            v-if="blok.subheadline"
            class="mt-4"
            :blok="{ text: blok.subheadline }"
          />
        </div>
        <CTAGroup v-if="blok.links" :ctas="blok.links" />
        <p v-if="blok.legalText" class="legal-text mt-8">
          {{ blok.legalText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CalloutBlokProps } from '@/types';

// Props
const props = defineProps<{
  blok: CalloutBlokProps;
}>();

const image = () => {
  const image = props.blok.background_image;

  const processedImage = imageService(image.filename, {
    focus: image.focus,
    size: 1440 + 'x' + 800,
  });

  return processedImage;
};
</script>

<style lang="scss">
.callout {
  --darkest: var(--dark-gray);
  --highlight: var(--bright-green);
  --lightest: var(--white);

  align-items: center;
  background-position: top center;
  background-size: cover;
  border-radius: var(--section-border-radius) var(--section-border-radius) 0 0;
  color: var(--white);
  display: flex;
  height: 100vw;
  justify-content: center;
  margin-block-end: calc(var(--section-border-radius) * -1);
  max-height: 800px;
  min-height: 568px;
  overflow: hidden;
  position: relative;
  text-align: center;

  &::before {
    background-color: rgb(0 0 0 / var(--overlay-alpha));
    content: '';
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
  }

  .custom-heading * {
    hyphens: auto;
  }

  &__headline {
    max-width: 900px;
    width: 100%;
  }

  &__content {
    padding-block: 0;
    padding-inline: var(--side-spacing);
    position: relative;
  }

  .cta-group {
    align-items: center;
    justify-content: center;
    margin-block-start: calc(var(--spacing-l) - 10px);

    .cta {
      min-width: 142px;
    }
  }

  .legal-text {
    color: var(--neutral-4);
    font-size: 10px;
  }

  @media all and #{$mq-xs-max} {
    .cta:not(.cta--app-icons) {
      width: 100%;
    }
  }
}
</style>
